import React from 'react'
import PropTypes from 'prop-types'
import {articlesLimitPerPage} from '../../../constants'
import s from './blogPagination.module.scss'

function BlogPagination({params: {page, pages, total}, onPage}) {

    if (total <= articlesLimitPerPage) { return null }
    
    return (
        <div className={[s.pagination, 'mt-5 mb-5'].join(' ')}>
            {[...new Array(pages + 1).join(' ')]
                .map((dot, id) => {
                    const currentId = id + 1

                    return (
                        <div key={id} className={[s.dot, page === currentId ? s.current: ''].join(' ')}
                             onClick={() => page !== currentId && onPage(currentId)}>
                            {currentId}
                        </div>
                    )
                })}
        </div>
    );
}

BlogPagination.propTypes = {
    params: PropTypes.shape({
        page: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
    }).isRequired
}

export default BlogPagination;