import {Fragment} from 'react'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import Header from './components/header'
import Main from './pages/main'
import Blog from './pages/blog'
import Article from './pages/article'
import Solution from './pages/solution'
import Footer from './components/footer'
import Policy from "./components/policy"
import BlogState from './components/blogState'
import ScrollTop from './components/scrollTop'
import PolicyProvider from './components/policy/policy.provider'

function App() {
  return (
    <Fragment>
        <Router>
            <BlogState>
                <ScrollTop/>
                <Header />
                <Route path="/" exact component={Main} />
                <Route path="/solution" component={Solution} />
                <Switch>
                    <Route path="/blog/:id" component={Article} />
                    <Route path="/blog" component={Blog} />
                </Switch>
                <PolicyProvider>
                    <Footer/>
                    <Policy />
                </PolicyProvider>
            </BlogState>
        </Router>
    </Fragment>
  );
}

export default App;
