import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import UserCard from '../../userCard'
import Title from '../../title'
import Text from '../../text'
import Tags from '../../tags'
import Sprite from '../../sprite'
import s from '../content.module.scss'

const TitleWrapper = ({children, postId, isMobile, isActive, onToggle}) => {
    if (isMobile) {
        return (
            <Fragment>
                {React.cloneElement(children, {
                    onClick: () => onToggle(postId),
                    style: {'cursor': 'pointer'}
                })}
                <Sprite onPress={() => onToggle(postId)} name="frame-arrow" className={
                    [s.arrow, "info__arrow", isActive ? s.arrow_active : null].join(' ')} />
            </Fragment>
        )
    }

    return (
        <Link to={`/blog/${postId}`}>
            {children}
        </Link>
    )
}

const ArticleItem = ({isOpen, article, isMobile, toggleHandler}) => {
    const isActive = isOpen === article.id

    return (
        <div className="col-lg-4 mb-5" key={article.id}>
            <div className={s.card}>
                <div className={[s.cardThumb, 'mb-3 float-left float-lg-none'].join(' ')} style={{
                    backgroundImage: `url(${article.feature_image})`
                }} />
                <Tags tags={article.tags} className="d-none d-lg-block mb-2" />
                <TitleWrapper
                    postId={article.id}
                    isMobile={isMobile}
                    isActive={isActive}
                    onToggle={toggleHandler}
                >
                    <Title small text={article.title}
                           className={[s.title, 'mb-3'].join(' ')} />
                </TitleWrapper>
                <div className={['float-left float-lg-none',
                    isMobile ? s.collapse : null, isActive ? s.open: null
                ].join(' ')}>
                    <Tags tags={article.tags} className="d-lg-none mb-2" />
                    <Text className="mb-3" small>
                        {article.excerpt}
                        {isMobile && (
                            <Link to={`/blog/${article.id}`}>
                                <span style={{color: '#4144C3'}}> read more...</span>
                            </Link>
                        )}
                    </Text>
                    <UserCard
                        className="mb-4"
                        userName={article.primary_author.name}
                        thumbnail={article.primary_author.profile_image}
                        timeCreated={article.created_at}
                        readTime={article.reading_time} />
                </div>
            </div>
        </div>
    )
}

ArticleItem.propTypes = {
    isMobile: PropTypes.bool.isRequired,
    article: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        excerpt: PropTypes.string,
        created_at: PropTypes.string,
        feature_image: PropTypes.string,
        reading_time: PropTypes.number,
        tags: PropTypes.array,
        primary_author: PropTypes.shape({
            name: PropTypes.string,
            profile_image: PropTypes.string
        }),
    })
}

export default ArticleItem;
