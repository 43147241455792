import React, {useContext} from 'react';
import Title from "../title";
import Text from "../text";
import {PolicyContext} from '../../context'
import s from './policy.module.scss'


function Policy() {
    const {isOpen, isConfirm, onToggle, onConfirm} = useContext(PolicyContext);

    const style = isOpen ?
        {transform: 'translateY(0)'}:
        {transform: 'translateY(110%)'};

    return (
        <div className={['container-fluid pb-3 pt-3', s.ppl].join(' ')} style={style}>
            <span className={s.closeIco} onClick={onToggle} />
            <div className="row">
                <div className="col-12">
                    <Title className={[s.title, 'mb-1'].join(' ')} text="This website uses cookies" small/>
                    <Text className={s.text} small>
                        We use cookies to personalise content and ads, to provide social media features and to analyse our
                        traffic. We also share information about your use of our site with our social media, advertising and
                        analytics partners who may combine it with other information that you’ve provided to them or that
                        they’ve collected from your use of their services
                    </Text>
                    <button
                        onClick={onConfirm}
                        className={['button mt-2', s.btn, isConfirm && s.btnDisabled].join(' ')}
                        disabled={isConfirm}
                    >
                        {isConfirm ? 'allowed': 'allow cookies'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Policy;