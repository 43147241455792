import React from 'react'
import {number} from 'prop-types'
import Sprite from '../sprite'

const Rates = ({rate}) => (
    <div className="d-flex align-items-start ml-2">
        {[1,2,3,4,5].map((star, id) =>
            <Sprite key={id} name={id < rate ? 'star1': 'star0' } />
        )}
    </div>
)

Rates.propTypes = {
    rate: number.isRequired
}

export default Rates;
