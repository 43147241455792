import {useState, useEffect, useCallback} from 'react'
import {useWindowSize} from '@react-hook/window-size'
import Header from './header'

function HeaderContainer() {
    const [open, setOpen] = useState(false)
    const [width] = useWindowSize()
    const isMobile = width <= 992

    const onToggle = () => {
        setOpen(prevState => !prevState)
    }

    const onClose = useCallback(
        () => {open && onToggle()}, [open]
    )

    useEffect(() => {
        if (!isMobile) {
            onClose()
        }
    }, [width, isMobile, onClose])

    return (
        <Header
            isOpen={open}
            isMobile={isMobile}
            onToggle={onToggle}
            onClose={onClose} />
    );
}

export default HeaderContainer;