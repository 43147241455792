import React from 'react';
import {string, func} from 'prop-types'
import './sprite.scss'

const Sprite = ({name, className, onPress}) => (
    <span onClick={onPress} className={`sprite sprite-${name} ${className}`} />)

Sprite.defaultProps = {
    className: '',
    onPress: () => {}
}

Sprite.propTypes = {
    name: string.isRequired,
    className: string,
    onPress: func
}

export default Sprite;
