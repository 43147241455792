import React from 'react'
import PropTypes from 'prop-types'
import s from './tags.module.scss'

const Tags = ({className, tags}) => (
    <div className={[s.t, className].join(' ')}>
        {tags.map(tag => ` ${tag.slug}`)}
    </div>
)

Tags.defaultProps = {
    className: ''
}

Tags.propTypes = {
    className: PropTypes.string,
    tags: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            slug: PropTypes.string
        })
    ).isRequired,
}

export default Tags;
