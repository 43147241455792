import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import BlogLastArticle from './blogLastArticle'
import ArticleItem from './articleItem/articleItem'
import BlogPagination from './blogPagination'
import s from './content.module.scss'

const BlogList = ({isOpen, articles, params, isMobile, toggleHandler, fetchArticles}) => (
    <Fragment>
        <BlogLastArticle article={articles[0]} />
        <div className={[s.cardWrap, 'row'].join(' ')}>
            {articles
                .slice(1)
                .map(article => (
                    <ArticleItem
                        key={article.id}
                        isOpen={isOpen}
                        article={article}
                        isMobile={isMobile}
                        toggleHandler={toggleHandler}
                    />
                ))}
        </div>
        <BlogPagination params={params} onPage={fetchArticles} />
    </Fragment>
)

BlogList.propTypes = {
    articles: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            excerpt: PropTypes.string,
            created_at: PropTypes.string,
            feature_image: PropTypes.string,
            reading_time: PropTypes.number,
            tags: PropTypes.array,
            primary_author: PropTypes.shape({
                name: PropTypes.string,
                profile_image: PropTypes.string
            }),
        })
    ).isRequired,
    params: PropTypes.object.isRequired,
    fetchArticles: PropTypes.func.isRequired,
    toggleHandler: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
    isOpen: PropTypes.string.isRequired
}

export default BlogList;