import React, {Fragment, useState} from 'react'
import {node} from 'prop-types'
import {Modal as BModal} from 'react-bootstrap'
import Title from '../title'
import Text from '../text'
import s from './modal.module.scss'

function ModalContainer(WrappedComponent) {
    return function Modal() {
        const [open, setOpen] = useState(false)
        const [success, setSuccess] = useState(false)
        
        const handleOpen = (status) => {
            setSuccess(status)
            setOpen(true)
        }

        const handleClose = () => {
            setOpen(false)
        }

        return (
            <Fragment>
                <WrappedComponent modalOpen={handleOpen} />
                <BModal show={open} onHide={handleClose}>
                    <div className={s.wrap}>
                        <div className={s.header} style={{
                            backgroundPositionY: success ? 'top': 'bottom'
                        }} />
                        <Title
                            small
                            className="text-center mt-5"
                            text={`${success ? 'Congratulations!': 'Fail!'}`} />
                        <Text className="text-center mt-2 mb-5">
                            {success ? 'Your email was sent' : 'Try again later'}
                        </Text>
                        <button
                            onClick={handleClose}
                            className={[ s.button , 'button'].join(' ')}>
                            ok
                        </button>
                    </div>
                </BModal>
            </Fragment>
        )
    }
}

ModalContainer.propTypes = {
    WrappedComponent: node.isRequired
}

export default ModalContainer;