import React from 'react';
import {string, bool} from 'prop-types'
import Sprite from '../sprite'
import s from './title.module.scss'

const Title = ({text, withIco, className, small, ...args}) => (
    <div className={[small ? s.wrapSmall : s.wrap, className].join(' ')} {...args}>
        {text} {withIco && !small && (
        <Sprite name="title mt-2" />)}
    </div>
)

Title.defaultProps = {
    withIco: true,
    className: '',
    small: false
}

Title.propTypes = {
    text: string.isRequired,
    withIco: bool,
    className: string,
    small: bool
}

export default Title;
