import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import s from './userCard.module.scss'

const UserCard = ({thumbnail, userName, timeCreated, readTime, className}) => {
    return (
        <div className={[s.author, className].join(' ')}>
            <div className={s.authorThumb} style={{
                backgroundImage: 'url(' + thumbnail + ')'
            }} />
            <div className={s.authorInfo}>
                <div className={[s.authorName, 'mt-2 mb-2'].join(' ')}>
                    {userName}
                </div>
                <div className={s.created}>
                    {moment(timeCreated)
                        .format('D MMM YYYY')} / {readTime || 1} min read
                </div>
            </div>
        </div>
    );
};

UserCard.defaultProps = {
    className: '',
    readTime: 1
}

UserCard.propTypes = {
    className: PropTypes.string,
    thumbnail: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    timeCreated: PropTypes.string.isRequired,
    readTime: PropTypes.number
}

export default UserCard;
