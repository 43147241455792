import React, {useState} from 'react';
import {PolicyContext} from '../../context'

function PolicyProvider({children}) {
    const [open, setOpen] = useState(false)
    const [confirm, setConfirm] = useState(false)

    const toggleHandler = () => {
        setOpen(prev => !prev)
    }

    const onConfirm = () => {
        if (!confirm) {
            setConfirm(prev => !prev)

            open && toggleHandler()
        }
    }


    return (
        <PolicyContext.Provider value={{
            isOpen: open,
            isConfirm: confirm,
            onConfirm,
            onToggle: toggleHandler
        }}>
            {children}
        </PolicyContext.Provider>
    );
}

export default PolicyProvider;