import React, {useEffect, useContext} from 'react';
import {string, func, bool} from 'prop-types'
import Text from '../../text';
import {PolicyContext} from '../../../context'
import s from './checbox.module.scss'

const Checkbox = ({name, register, isChecked, onCheck}) => {
    const {isConfirm, isOpen, onToggle} = useContext(PolicyContext)

    useEffect(() => {
        isConfirm && !isChecked && onCheck()
        // eslint-disable-next-line
    }, [isConfirm])

    return (
        <div className={s.wrap}>
            <div className={s.checkboxes} onClick={onCheck}>
                <input
                    ref={register({
                        required: {value: true, message: 'required accept'}
                    })}
                    name={name}
                    type="checkbox"
                    className={s.hidden}
                    onChange={() => {}}
                    checked={isChecked}
                />
                <div className={[s.visible, isChecked && s.checked].join(' ')} />
            </div>
            <Text className={s.text}>
                I accept and agree with Web site`s
                <span onClick={!isOpen ? () => onToggle(): null}> Privacy Policy</span>
            </Text>
        </div>
    )
}


Checkbox.propTypes = {
    name: string.isRequired,
    register: func.isRequired,
    isChecked: bool.isRequired,
    onCheck: func.isRequired
}

export default Checkbox;