import React, {Fragment, useState} from 'react';
import { Accordion} from 'react-bootstrap';
import {useWindowSize} from "@react-hook/window-size";
import Title from '../../components/title'
import Sprite from "../../components/sprite";
import Text from "../../components/text";
import solutionBanner from '../../assets/img/solution-banner.png';
import s from './solution.module.scss'




function Solution() {
    const [ active, setActive ] = useState('');
    const [width] = useWindowSize();
    const isMobile = width <= 767;

    const setActiveHandler = (title) => () => {
        active !== title ? setActive(title) : setActive('')
    };

    const textArr = [
        {eventKey:1,title:"Server to Server RTB Endpoints for Video, Display & Audio", text:"The RTB software platform supports the OpenRTB protocol. This feature  is especially useful for automating and accelerating trading in the programmatic marketplace"},
        {eventKey:2,title:"Tag-Based Display/Video Ad Server - Direct Integration", text:"Direct user interaction with advertising providers! The publisher simply inserts our link into the code of their site, app or video player and BidPULSE is ready to receive their ad requests, turn them into RTB requests and send them to demand-side partners"},
        {eventKey:3,title:"Fraud Detection Integration", text:"The application has a built-in search function and detects illegal and fraudulent activities. Thanks to this feature, all our users are securely protected from information leakage and malicious attacks"},
        {eventKey:4,title:"Advanced Black & White List Targeting", text:"The platform supports targeting based on various characteristics. You will be able to add black and white lists with creative IDs or Publisher IDs"},
        {eventKey:5,title:"Platform Branding ", text:"BidPULSE is completely customizable to reflect your company branding. Add your company logo and make the look and feel of the platform reflect your unique branding style!"},
        {eventKey:7,title:"Fast Multidimensional Reporting", text:"Things move quickly with digital advertising and reliable real-time data is key to stay on top of your game. Our real-time statistics updates  are  an important feature of the RTB platform that enable you to make the right decisions and maximize profit"},
        {eventKey:8,title:"Role-Based Admin Accounts", text:"The RTB platform supports the RBAC access control method where you can set up custom admin  roles and permissions. Provide only the necessary access to data and functionality for each platform admin with our  flexible permission system"},
        {eventKey:9,title:"Users Permission Management", text:"The ability to manage access permissions is a necessary and important part of any commercial platform. BidPULSE provides simple permission features to quickly update  user platform rights and settings"},
        {eventKey:10,title:"RTB Bidder", text:"The auctions support feature is an important advantage of the RTB platform. The program can work with three types of auctions: custom, first price, second price. During the bidding process, minimum bids, product costs, information about the auction winner, and other important information is  always recorded. This helps users to better navigate all campaign changes, to make accurate profit forecasts and control all payments and margins "},
        {eventKey:11,title:"Analytics", text:"Real-time analytics and reporting are available 24/7. Real-time data helps to minimize risks, maximize profit and is essential for improving your campaign results. With the right real-time data - you can be sure that you maximize your profit while serving the right information to the right customer at the right time"},
        {eventKey:12,title:"OpenRTB 2.x Demand-Partner Integration", text:"The fully functional version of the OpenRTB 2.x platform allows convenient interaction with partners. Using the universal platform, you will be able to analyze the needs of your potential customers, thereby increasing your sales efficiency"},
        {eventKey:13,title:"OpenRTB 2.x Direct integration", text:"Directional integration helps to effectively manage integrations with partners and  make the most profitable transactions. OpenRTB 2.x is one of the best ad campaign management tools on the market today"}
        ]
    const caseInfo = [
        [
            {
                eventKey: "1",
                title: "Data Export",
                text: "An App developer increases user acquisition efficiency by targeting only those who are more likely to convert"
            },
            {
                eventKey: "2",
                title: "Data Import"
            },
            {
                eventKey: "3",
                title: "Machine Learning"
            },
            {
                eventKey: "4",
                title: "Real-Time API",
            },
            {
                eventKey: "5",
                title: "Media Buying",
            }
            ],
        [
        {
            eventKey: "1",
            title: "Data Export",
            text: "A Mobile carrier efficiently communicates with the subscribers who are about to churn or would like to switch carriers"
        },
        {
            eventKey: "2",
            title: "Data Import"
        },
        {
            eventKey: "3",
            title: "Media Buying"
        }
        ],
        [
            {
                eventKey: "1",
                title: "Data Import",
                text: "An AdTech / MadTech developer builds a new product that has media buying capabilities without investing time into building RTB infrastructure"
            },
            {
                eventKey: "2",
                title: "Campaigns API",
            }
        ],
        [
            {
                eventKey: "1",
                title: "Transparent Connections",
                text: "An SSP / Ad network launches a self-serve programmatic DSP on their inventory"
            },
            {
                eventKey: "2",
                title: "Real-Time API",
            }
        ]
    ];
    return (
       <Fragment>
           <div className='container pt-5'>
               <Title text="Our solutions" />
               <div className={s.banner}>
                   <img src={solutionBanner}  alt=""/>
               </div>
               <div className="row">
                   {!isMobile && textArr.map(info => (
                       <div className="col-12 col-sm-6 mb-5" key={info.title}>
                           <Title text={info.title} withIco={false} className={s.infoTitle} />
                           <Text className={s.infoArticle}>
                               {info.text}
                           </Text>
                       </div>
                   ))}
                   {isMobile && <Accordion defaultActiveKey="" className={s.accordion}>
                       {textArr.map(item => (
                           <div className="mb-4 px-3" key={item.title}>
                               <Accordion.Toggle eventKey={item.eventKey} className={s.info__button} onClick={setActiveHandler(item.title)}>
                                   <Title text={item.title} withIco={false} className={s.infoTitle}/>
                                   <Sprite name="frame-arrow" className={["info__arrow",
                                       active === item.title ? "activeTab ": null
                                   ].join(' ')} />
                                   <Accordion.Collapse eventKey={item.eventKey} className={s.info__text}>
                                       <Text>
                                           {item.text}
                                       </Text>
                                   </Accordion.Collapse>
                               </Accordion.Toggle>
                           </div>
                       ))}
                   </Accordion>}
               </div>
           </div>
           <div className={s.bottom_gradient} />
           <div className={s.cases}>
               <div className="container pt-5 pb-5">
                   <div className="row">
                       <Title text="Use case" className={[s.case_title, "col-12 mb-4"].join(' ')} />
                       <div className="col-12 col-md-6 mt-md-5 d-flex justify-content-center justify-content-md-end">
                           <Sprite name="frame-case-1" />
                       </div>
                       <div className="col-12 col-md-6 mb-5 mt-md-5">
                           <div className="d-flex flex-wrap">
                               {caseInfo[0].map(item => (
                                   <div className={s.tab__title} key={item.eventKey}>
                                       <Sprite name="frame-case-5" />
                                       <Title small text={item.title} className="ml-2" />
                                   </div>
                               ))}
                           </div>
                           <div className="d-flex flex-column align-items-center align-items-md-baseline">
                               <div className={s.cases__tabs}>
                                   <span className={s.subTitle}>Splitting Traffic:</span> There are 1K apps and a TOP-10
                                   apps list, that will provide the most effective sales <br/>
                                   <span className={s.subTitle}>What should be done:</span> Manage campaigns, control
                                   budgets, share black/white lists <br/>
                                   <span className={s.subTitle}>Conclusion:</span> Save up to 50% of your budgets on
                                   tech fees
                               </div>
                           </div>
                       </div>
                       {/*<div className="col-12 col-md-6 mt-md-5 d-flex justify-content-center justify-content-md-end">*/}
                       {/*    <Sprite name="frame-case-2" />*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-md-6 mb-5 mt-md-5">*/}
                       {/*    <div className="d-flex flex-wrap">*/}
                       {/*        {caseInfo[1].map(item => (*/}
                       {/*            <div className={s.tab__title} key={item.eventKey}>*/}
                       {/*                <Sprite name="frame-case-5" />*/}
                       {/*                <Title small text={item.title} className="ml-2" />*/}
                       {/*            </div>*/}
                       {/*        ))}*/}
                       {/*    </div>*/}
                       {/*    <div className="d-flex flex-column align-items-center align-items-md-baseline">*/}
                       {/*        <div className={s.cases__tabs}>*/}
                       {/*            A Mobile carrier efficiently communicates with the subscribers who are about to churn*/}
                       {/*            or would like to switch carriers*/}
                       {/*        </div>*/}
                       {/*    </div>*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-md-6 mt-md-5 d-flex justify-content-center justify-content-md-end">*/}
                       {/*    <Sprite name="frame-case-3" />*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-md-6 mb-5 mt-md-5">*/}
                       {/*    <div className="d-flex flex-wrap">*/}
                       {/*        {caseInfo[2].map(item => (*/}
                       {/*            <div className={s.tab__title} key={item.eventKey}>*/}
                       {/*                <Sprite name="frame-case-5" />*/}
                       {/*                <Title small text={item.title} className="ml-2" />*/}
                       {/*            </div>*/}
                       {/*        ))}*/}
                       {/*    </div>*/}
                       {/*    <div className="d-flex flex-column align-items-center align-items-md-baseline">*/}
                       {/*        <div className={s.cases__tabs}>*/}
                       {/*            An AdTech / MadTech developer builds a new product that has media buying capabilities*/}
                       {/*            without investing time into building RTB infrastructure*/}
                       {/*        </div>*/}
                       {/*    </div>*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-md-6 mt-md-5 d-flex justify-content-center justify-content-md-end">*/}
                       {/*    <Sprite name="frame-case-4" />*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-md-6 mb-5 mt-md-5">*/}
                       {/*    <div className="d-flex flex-wrap">*/}
                       {/*        {caseInfo[3].map(item => (*/}
                       {/*            <div className={s.tab__title} key={item.eventKey}>*/}
                       {/*                <Sprite name="frame-case-5" />*/}
                       {/*                <Title small text={item.title} className="ml-2" />*/}
                       {/*            </div>*/}
                       {/*        ))}*/}
                       {/*    </div>*/}
                       {/*    <div className="d-flex flex-column align-items-center align-items-md-baseline">*/}
                       {/*        <div className={s.cases__tabs}>*/}
                       {/*            An SSP / Ad network launches a self-serve programmatic DSP on their inventory*/}
                       {/*        </div>*/}
                       {/*    </div>*/}
                       {/*</div>*/}
                   </div>
               </div>
           </div>
           {/*<div className="container pt-5 pb-5">*/}
           {/*    <div className="row">*/}
           {/*        <Title text="Use Case" withIco={false} className="col-12"/>*/}
           {/*        <div className={[s.info_block, 'col-12 d-flex justify-content-center'].join(' ')}>*/}
           {/*            <Text>*/}
           {/*               <span className={s.subTitle}>Splitting Traffic:</span> There are 1K apps and a  TOP-10 apps list, that will provide the most effective sales <br/>*/}
           {/*                <span className={s.subTitle}>What should be done:</span> Manage campaigns, control budgets, share black/white lists <br/>*/}
           {/*                <span className={s.subTitle}>Conclusion:</span> Save up to 50% of your budgets on tech fees*/}
           {/*            </Text>*/}
           {/*        </div>*/}
           {/*    </div>*/}
           {/*</div>*/}
       </Fragment>
    );
}


export default Solution;