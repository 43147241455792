import React, {useReducer, useCallback} from 'react'
import {articlesLimitPerPage} from '../../constants'
import {BlogContext} from '../../context'
import {api} from '../../utils'

const initialState = {
    loading: true,
    data: [],
    params: {},
    error: '',
    isLoaded: false,
}

function reducer(state, {type, payload}) {
    switch (type) {
        case 'REQUEST':
            return {
                ...state,
                loading: true,
                data: [],
                isLoaded: false,
            }
        case 'RESPONSE':
            return  {
                ...state,
                loading: false,
                data: [...state.data, ...payload],
                params: payload.meta['pagination'],
                isLoaded: true
            }
        case 'FAILURE':
            return  {
                ...state,
                loading: false,
                data: [],
                error: payload.error,
                isLoaded: false
            }
        default:
            return state
    }
}

function BlogState({children}) {
    const [state, dispatch] = useReducer(reducer, initialState)

    const fetchArticles = useCallback(
        (page) => {
            dispatch({type: 'REQUEST'})

            api.posts
                .browse({limit: articlesLimitPerPage, page: page || 1, include: 'tags,authors'})
                .then((posts) => {dispatch({type: 'RESPONSE', payload: posts})})
                .catch((err) => {dispatch({type: 'FAILURE', payload: err})})
        }, []
    )

    return (
        <BlogContext.Provider value={{
            articles: state.data,
            loading: state.loading,
            isLoaded: state.isLoaded,
            params: state.params,
            fetchArticles
        }}>
            {children}
        </BlogContext.Provider>
    );
}

export default BlogState;