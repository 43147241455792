import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import UserCard from '../../userCard'
import Title from '../../title'
import Text from '../../text'
import Tags from '../../tags'
import s from '../content.module.scss'

const BlogLastArticle = ({article}) => (
    <div className="row">
        <div className="col-lg-7">
            <div className={[s.bThumb, 'mb-3'].join(' ')}
                 style={{'backgroundImage': `url(${article.feature_image})`}} />
        </div>
        <div className="col-lg-5">
            <Tags tags={article.tags} className="mb-2" />
            <Link to={`/blog/${article.id}`}>
                <Title
                    className={[s.title, 'mb-3'].join(' ')}
                    text={article.title}
                    withIco={false}
                    small />
            </Link>
            <Text className="mb-3" small>
                {article.excerpt}
            </Text>
            <UserCard
                userName={article.primary_author.name}
                thumbnail={article.primary_author.profile_image}
                timeCreated={article.created_at}
                readTime={article.reading_time} />
        </div>
    </div>
)

BlogLastArticle.propTypes = {
    article: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        excerpt: PropTypes.string,
        created_at: PropTypes.string,
        feature_image: PropTypes.string,
        reading_time: PropTypes.number,
        tags: PropTypes.array,
        primary_author: PropTypes.shape({
            name: PropTypes.string,
            profile_image: PropTypes.string
        }),
    })
}

export default BlogLastArticle;
