import React from 'react'
import {bool, func} from 'prop-types'
import {Link} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import Sprite from '../sprite'
import {default as MobileMenu} from './menu'
import s from './header.module.scss'

const Header = ({isOpen, isMobile, onToggle, onClose}) => (
    <header className={s.wrap} >
        <div className="container">
            <Link to="/">
                <img width="110" height="47" src="/logo.svg" alt="BidPulse.io" />
            </Link>
            <div className="d-none d-lg-flex">
                <nav className={s.navMenu}>
                    <Link className={s.navLink} to="/solution">Solution</Link>
                    <Link className={s.navLink} to="/blog">Our Blog</Link>
                    <HashLink className={s.navLink} smooth to="/#contact-us">Contact us</HashLink>
                </nav>
                <a className={s.btn} href="http://ui.bidpulse.io/console/login" target="_blank" rel="noreferrer">Log in</a>
            </div>
            <a className={[s.regBtn, 'd-lg-none', 'mr-3'].join(' ')} href="http://ui.bidpulse.io/console/login" target="_blank" rel="noreferrer">
                <Sprite name="reg" />
            </a>
        </div>
        {isMobile && (
            <MobileMenu isOpen={isOpen} onClose={onClose} onToggle={onToggle} />
        )}
    </header>
)

Header.propTypes = {
    isOpen: bool.isRequired,
    isMobile: bool.isRequired,
    onClose: func.isRequired,
    onToggle: func.isRequired
}

export default Header
