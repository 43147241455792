import React, {Fragment} from 'react'
import Title from '../../components/title'
import Text from '../../components/text'
import Rates from '../../components/rates'
import Sprite from '../../components/sprite'
import img from '../../assets/img/platform.png'
import mainBanner from '../../assets/img/main-banner.jpg'
import group from '../../assets/img/Group146.png'
import s from './main.module.scss'
import {HashLink} from 'react-router-hash-link'


const Main = () => {
    return (
        <Fragment>
            <div className={s.frame1}>
                <div className="container pt-5 pb-5">
                    <div className="row ">
                        <div className="col-12 col-lg-6 order-2 order-lg-1 text-center text-lg-left">
                            <div className={s.frame1Article}>
                                Manage supply and demand on a global scale <br/> All on one platform
                            </div>
                            <HashLink className="button mt-5" smooth to="/#contact-us">Get in Touch</HashLink>
                        </div>
                        <div className="col-12 col-lg-6 order-1">
                            <img src={mainBanner} className={s.frame1Img} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container pt-5">
                <Title text="What is OUR platform?" />
                <div className="row mt-5">
                    <div className="col-12 col-lg-8 order-2 order-lg-1">
                        <Text>
                            BidPULSE is a White Label programmatic platform that enables profitable
                            resource-intensive operations for digital ad companies and brands
                        </Text>
                        <br/>
                        <Text>
                            This hassle-free
                            integration greatly contributes to the rapid development of your advertising business
                        </Text>
                        <br/>
                        <Text>
                            Our White Label software module integrates SSP and DSP with ad networks
                        </Text>
                        <br/>
                        <Text>
                            With
                            BidPULSE you can quickly and efficiently manage digital advertising activities, promptly
                            fix any issues and speed up the ROI on campaigns
                        </Text>
                        <br/>
                        <Text className="pb-5">
                            The platform offers flexible settings
                            and convenient functionality with real-time data
                        </Text>
                    </div>
                    <div className="col-12 col-lg-4 order-1 text-center">
                        <img src={img} style={{'maxWidth': '100%'}} alt="" />
                    </div>
                </div>
            </div>
            <div className={s.frame3}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 pl-0 pr-0">
                            <div className={[s.frame3Top, 'd-flex flex-column flex-lg-row align-items-center align-items-lg-end pl-3 pr-3 pt-5 pb-5'].join(' ')}>
                                <Sprite name="frame2-1" className={s.frame2Sprite} />
                                <Title text="Save on development" withIco={false} className={s.frame2Title} />
                            </div>
                        </div>
                        <div className="col-lg-4 pl-0 pr-0">
                            <div className={[s.frame3Top, 'd-flex flex-column flex-lg-row align-items-center align-items-lg-end pl-3 pr-3 pt-5 pb-5'].join(' ')}>
                                <Sprite name="frame2-2" className={s.frame2Sprite} />
                                <Title text="Customize to match your brand" withIco={false} className={s.frame2Title} />
                            </div>
                        </div>
                        <div className="col-lg-4 pl-0 pr-0">
                            <div className={[s.frame3Top, 'd-flex flex-column flex-lg-row align-items-center align-items-lg-end pl-3 pr-3 pt-5 pb-5'].join(' ')}>
                                <Sprite name="frame2-3" className={s.frame2Sprite} />
                                <Title text="Get expert support" withIco={false} className={s.frame2Title} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Text className="col-12 pt-5 pb-5">
                            Our service is a fully functional platform that connects SSPs and DSPs with ad networks, which contributes to increased sales efficiency.
                            The new technology enables digital ad companies to conduct effective programmatic deals. Promote own brand, and speed up the return on investment on digital campaigns with excellent manageability and minimal financial investments. The BidPULSE platform is an effective solution to manage your digital advertising efforts!
                        </Text>
                    </div>
                </div>
            </div>
            <div className={s.frame4}>
                <div className="container pt-5 pb-5">
                    <Title text="Hyper-target your ad campaigns across multiple promotion channels" className={s.frame4Title} />
                    <br/>
                    <div className={s.frame4ItemWrap}>
                        <div className={s.frame4Item}>
                            <Sprite name="frame4-1" />
                            <Title text="Banner" small />
                        </div>
                        <div className={s.frame4Item}>
                            <Sprite name="frame4-2" />
                            <Title text="Video" small />
                        </div>
                        <div className={s.frame4Item}>
                            <Sprite name="frame4-3" />
                            <Title text="Native" small />
                        </div>
                        <div className={s.frame4Item}>
                            <Sprite name="frame4-4" />
                            <Title text="Push" small />
                        </div>
                        <div className={s.frame4Item}>
                            <Sprite name="frame4-5" />
                            <Title text="Pop" small />
                        </div>
                        <div className={s.frame4Item}>
                            <Sprite name="frame4-6" />
                            <Title text="CTV" small />
                        </div>
                        <div className={s.frame4Item}>
                            <Sprite name="frame4-7" />
                            <Title text="Audio ads" small />
                        </div>
                    </div>
                    <img src={group} className="d-block d-lg-none ml-auto mr-auto" alt="" />
                    <br/>
                    <Text>
                        With BidPULSE RTB you can be sure that your ads are show to the right
                        audience, at the right time and, most importantly, you’ll be able to
                        measure the efficiency of your deals with accurate real-time analytics
                        tools
                    </Text>
                </div>
            </div>
            <div className={s.tl}>
                <div className="container pt-5 pb-5">
                    <Title text="Testimonials" />
                    <br/>
                    <br/>
                    <Text>
                        BidPULSE  is an ultimate customizable white label full stack programmatic platform that directly connects SSP, DSP, and ad networks to help you grow faster. Our robust technology enables transparent, resource-efficient, and profitable advertising operations for digital ad agencies, networks, and brands
                    </Text>
                    <br/>
                    <br/>
                    <div className="row">
                        <div className="col-lg-4 mb-5">
                            <div className={[s.tlCard, 'pb-5'].join(' ')}>
                                <div className={[s.tlCardTop, 'mb-2'].join(' ')}>
                                    <Sprite name="tm-ico-1" className={s.tlCardThumb} />
                                    <div className={s.tlCardTopInfo}>
                                        <Title
                                            text="Alice K."
                                            withIco={false}
                                            className="mb-2"
                                            small />
                                        <Text className="mb-2" small>
                                            Ad Tech Evangelist
                                        </Text>
                                        <div className={s.tlRates}>
                                            <Sprite name="rate" />
                                            <Rates rate={5} />
                                        </div>
                                    </div>
                                </div>
                                <Text small>
                                    I`m able to optimize on the fly and reach my goals faster than before. Super easy setup
                                    and intuitive UI
                                </Text>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className={[s.tlCard, 'pb-5'].join(' ')}>
                                <div className={[s.tlCardTop, 'mb-2'].join(' ')}>
                                    <Sprite name="tm-ico-2" className={s.tlCardThumb} />
                                    <div className={s.tlCardTopInfo}>
                                        <Title
                                            text="Gregory N."
                                            withIco={false}
                                            className="mb-2"
                                            small />
                                        <Text className="mb-2" small>
                                            Programmatic Operations Manager
                                        </Text>
                                        <div className={s.tlRates}>
                                            <Sprite name="rate" />
                                            <Rates rate={5} />
                                        </div>
                                    </div>
                                </div>
                                <Text small>
                                    This is a complete, all-in-one solution for everything I need to run my campaigns. My
                                    clients are happy and so am I
                                </Text>
                            </div>
                        </div>
                        <div className="col-lg-4 mb-5">
                            <div className={[s.tlCard, 'pb-5'].join(' ')}>
                                <div className={[s.tlCardTop, 'mb-2'].join(' ')}>
                                    <Sprite name="tm-ico-3" className={s.tlCardThumb} />
                                    <div className={s.tlCardTopInfo}>
                                        <Title
                                            text="Nina V."
                                            withIco={false}
                                            className="mb-2"
                                            small />
                                        <Text className="mb-2" small>
                                            Publisher account manager
                                        </Text>
                                        <div className={s.tlRates}>
                                            <Sprite name="rate" />
                                            <Rates rate={4} />
                                        </div>
                                    </div>
                                </div>
                                <Text small>
                                    BidPULSE was my entry into programmatic and, like with any complex tool, there was a learning curve, but the support staff was really great at helping me figure everything out step by step. I now consider myself a total pro and have the results to back it up
                                </Text>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Main;
