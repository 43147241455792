import React from 'react'
import {string, arrayOf, shape, object} from 'prop-types'
import {Controller} from 'react-hook-form'
import {default as RSelect} from 'react-select'

function Select({name, control, options}) {
    const customOptions = {
        control: (provided) => ({
            ...provided,
            height: 52,
            borderRadius: 10,
            fontSize: 22,
            fontWeight: 300,
            paddingLeft: 15,
            //lineHeight: 50
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#58595B',
            paddingRight: 50
        }),
        valueContainer: (provided) => ({
           height: '100%'
        }),
        container: (provided) => ({
            ...provided,
        })
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={''}
            rules={{
                required: {value: true, message: 'required field'}
            }}
            render={({ onChange, value, name, ref }) => (
                <RSelect
                    inputRef={ref}
                    placeholder="---"
                    options={options}
                    styles={customOptions}
                    value={options.find(selected => selected.value === value)}
                    onChange={val => onChange(val.value)}
                />
            )}
        />

    );
}

Select.propTypse = {
    control: object.isRequired,
    name: string.isRequired,
    options: arrayOf(
        shape({
            value: string,
            label: string
        })
    ).isRequired
}

export default Select;