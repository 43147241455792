import React from 'react';
import {string, node, bool} from 'prop-types'
import s from './text.module.scss'

const Text = ({children, className, small}) => {
    return (
        <div className={[!small ? s.wrap: s.wrapSmall, className].join(' ')}>
            {children}
        </div>
    );
};

Text.defaultProps = {
    children: '',
    small: false
}

Text.propTypes = {
    children: node.isRequired,
    className: string,
    small: bool
}

export default Text;
