import React from 'react';
import Sprite from '../sprite'
import Text from '../text'
import Form from '../form'
import s from './footer.module.scss'

const Footer = () => {
    return (
        <div className={s.wrap}>
            <div className="container pt-5 pb-5">
                <div className="row">
                    <div className="col-lg-6 mb-5 mb-lg-0">
                        <div className={s.wrapTitle}>
                            about us <Sprite name="foot" />
                        </div>
                        <Text className={s.text}>
                            We are marketing and tech geeks with over 12 years of experience in the game. BidPULSE  is our vision of  the future of digital ads
                        </Text>
                        <br/>
                        <Text className={s.text}>
                            Our platform is designed specifically for the advertising industry. The released software includes tools for advertising management and traffic monetization. Among its users are digital advertisers and agencies all over the world
                        </Text>
                        <br/>
                        <Text className={s.text}>
                            BidPULSE is the culmination of over  four years of hard work and  software development. Our White Label advertising company uses a highly efficient application that works with the OpenRTB protocol. BidPULSE uses advanced algorithms and own data to improve  and perfect online advertising
                        </Text>
                    </div>
                    <div className="col-lg-6" id="contact-us">
                        <div className={s.wrapTitle}>
                            CONTACT US <Sprite name="foot" />
                        </div>
                        <Text className={[s.contact_text, 'mt-3 mb-3 text-center text-lg-left'].join(' ')}>
                            Ready to join the future? Let's talk!
                        </Text>
                        <Form />
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-6" />
                    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center col-lg-6">
                        <Sprite name="logo" />
                        <br/>
                        <Text className={s.copy} small>Copyright © 2021 Bidpulse</Text>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
