import React from 'react'
import {bool, func} from 'prop-types'
import {Link} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import './menu.scss'

const Menu = ({isOpen, onClose, onToggle}) => (
    <div className={`mob-menu ${isOpen ? 'open': ''}`}>
        <div className="mob-menu__btn" onClick={onToggle}>
            <div className="mob-menu__burger" />
        </div>
        <div className="mob-menu__links mb-5">
            <Link to="/solution" onClick={onClose}>
                Solution
            </Link>
            <Link to="/blog" onClick={onClose}>
                Our Blog
            </Link>
            <HashLink smooth to="/#contact-us" onClick={onClose}>
                Contact us
            </HashLink>
            <a className="button text-center mt-3" href="http://ui.bidpulse.io/console/login" target="_blank" rel="noreferrer">Log in</a>
        </div>
    </div>
)

Menu.propTypes = {
    isOpen: bool.isRequired,
    onClose: func.isRequired,
    onToggle: func.isRequired
}

export default Menu;