import React from 'react';
import {bool, string} from 'prop-types'
import preload from '../../assets/img/preloader.gif'
import s from './preloader.module.scss'

const Preloader = ({className, small}) => {
    return (
        <div className={[small ? s.wrapSmall : s.wrap, className].join(' ')}>
            <img className={s.gifIco} src={preload} alt="" />
        </div>
    );
};

Preloader.defaultProps = {
    className: '',
    small: false
}

Preloader.propTypes = {
    className: string,
    small: bool
}

export default Preloader;
