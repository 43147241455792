import React, {useState} from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import {useForm} from 'react-hook-form'
import Preloader from '../preloader'
import Checkbox from './checkbox'
import Select from './select'
import {default as withModal} from '../modal'
import s from './form.module.scss'

const ErrorField = ({message}) => (
    message ? <div className={s.error}>{message}</div> : null
)

function Form({modalOpen}) {
    const {handleSubmit, errors, control, register, reset} = useForm()
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleCheck = () => {
        setChecked(prev => !prev)
    }

    const onSubmit = (data) => {
        setLoading(true)

        axios.post('/send.php', data, { // add the domain url
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(({data: {success}}) => {
                modalOpen(success)
                handleCheck()
                reset()
            })
            .catch(error => {
                modalOpen(false)
                console.log('error: ', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className={s.field}>
                <Select
                    name="topic"
                    control={control}
                    options={[
                        {value: 'partnership', label: 'Partnership'},
                        {value: 'support', label: 'Support'},
                        {value: 'request_a_demo', label: 'Request a demo'}
                    ]}/>
                <ErrorField {...errors.topic} />
            </div>
            <div className={s.field}>
                <input
                    type="text"
                    name="name"
                    ref={register({
                        required: {value: true, message: 'required field'},
                        minLength: {value: 3, message: 'Minimum 3 characters required'},
                        pattern: {value: /^[A-z0-9\s]+$/, message: 'Name is not valid'}
                    })}
                    placeholder="Name*"
                />
                <ErrorField {...errors.name} />
            </div>
            <div className={s.field}>
                <input
                    type="email"
                    name="email"
                    ref={register({
                        required: {value: true, message: 'required field'},
                        pattern: {value: /\S+@\S+\.\S+/, message: 'Email: not valid'}
                    })}
                    placeholder="Email*"
                />
                <ErrorField {...errors.email} />
            </div>
            <div className={s.field}>
                <input
                    type="text"
                    name="company"
                    ref={register({
                        required: {value: true, message: 'required field'}
                    })}
                    placeholder="Your company"
                />
                <ErrorField {...errors.company} />
            </div>
            <div className={s.field}>
                <textarea
                    rows={5}
                    name="text"
                    ref={register({
                        required: {value: true, message: 'required field'},
                        minLength: {value: 20, message: 'Minimum 20 characters required'},
                    })}
                    placeholder="You message"
                />
                <ErrorField {...errors.text} />
            </div>
            <Checkbox
                name="agree"
                isChecked={checked}
                register={register}
                onCheck={handleCheck}
            />
            <ErrorField {...errors.agree} />
            <button type="submit" disabled={loading} className={['button', s.submit, 'mt-4'].join(' ')}>
                {loading ? <Preloader small /> : 'send'}
            </button>
        </form>
    );
}

Form.propTypes = {
    modalOpen: PropTypes.func.isRequired
}

export default withModal(Form);