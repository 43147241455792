import React from 'react';
import Title from '../../components/title'
import BlogList from '../../components/blogList'

const Blog = () => (
    <div className="container pt-5 pb-5">
        <Title text="Our Blog" className="mb-5" />
        <BlogList />
    </div>
)

export default Blog;