import React from 'react'
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import Title from '../../components/title'
import Text from '../../components/text'
import UserCard from '../../components/userCard'
import Tags from '../../components/tags'
import s from './article.module.scss'

const Article = ({data}) => (
    <div className="container pt-5 pb-5">
        <div className={s.maxWidth}>
            <Title text="Our Blog" className="mb-5" />
            <Tags tags={data.tags} className="mb-2" />
            <div className={[s.title, 'mb-5'].join(' ')}>
                {data.title}
            </div>
            <UserCard
                className="mb-5"
                thumbnail={data.primary_author.profile_image}
                userName={data.primary_author.name}
                timeCreated={data.created_at}
                readTime={data.reading_time} />
        </div>
        <img src={data.feature_image} className={s.thumb} alt="" />
        <div className={s.maxWidth}>
            <Text className="mt-5">
                <div dangerouslySetInnerHTML={{__html: data.html}} />
            </Text>
            <Link to="/blog">
                <button className="button d-block mt-5 ml-auto mr-auto">BACK to BLOG</button>
            </Link>
        </div>
    </div>
)

Article.propTypes = {
    data: PropTypes.shape({
        title: PropTypes.string,
        created_at: PropTypes.string,
        feature_image: PropTypes.string,
        tags: PropTypes.array,
        html: PropTypes.string,
        primary_author: PropTypes.shape({
            name: PropTypes.string,
            profile_image: PropTypes.string
        }),
        reading_time: PropTypes.number
    }).isRequired
}

export default Article;
