import React, {useState, useEffect} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import Article from './article'
import {api} from '../../utils'
import Preloader from "../../components/preloader";

function ArticleContainer({match: {params}}) {
    const [data, setData] = useState({})
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)

        api.posts.read({id: params.id, include: 'tags,authors'})
            .then((posts) => {
                setData(prev => Object.assign(prev, posts))
            })
            .catch((err) => {
                console.log('error', err)
                setError(true)
            })
            .finally(() => {
                setLoading(false)
            })
        // eslint-disable-next-line
    }, [])

    if (loading) {
        return <Preloader className="mt-5 " />
    }

    if (error) {
        return <div className="container alert alert-dark mt-5 mb-5" role="alert">Validation failed for post id</div>
    }

    return (
        <Article data={data} />
    );
}

ArticleContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
        })
    }).isRequired
}

export default withRouter(ArticleContainer)