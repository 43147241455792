import React, {useState, useContext, useEffect, Fragment} from 'react'
import {useWindowSize} from '@react-hook/window-size'
import BlogList from './blogList'
import Preloader from '../preloader'
import {BlogContext} from '../../context'

function BlogListContainer() {
    const [open, setOpen] = useState('')
    const {loading, articles, isLoaded, params, fetchArticles} = useContext(BlogContext)
    const [width] = useWindowSize()
    const isMobile = width <= 768

    const toggleHandler = (postId) => {
        setOpen(prevState => prevState === postId ? '': postId)
    }


    useEffect(() => {
        !isLoaded && fetchArticles()
        // eslint-disable-next-line
    }, [])

    if (loading) { return  <Preloader /> }

    return (
        <Fragment>
            {!!articles.length && (
                <BlogList
                    isOpen={open}
                    params={params}
                    articles={articles}
                    isMobile={isMobile}
                    fetchArticles={fetchArticles}
                    toggleHandler={toggleHandler}
                />
            )}
        </Fragment>
    );
}

export default BlogListContainer;